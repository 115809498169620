@import './colors2';

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('assets/fonts/montserrat-v15-latin-300.eot');
    src: local(''), url('assets/fonts/montserrat-v15-latin-300.eot?#iefix') format('embedded-opentype'), url('assets/fonts/montserrat-v15-latin-300.woff2') format('woff2'), url('assets/fonts/montserrat-v15-latin-300.woff') format('woff'), url('assets/fonts/montserrat-v15-latin-300.ttf') format('truetype'), url('assets/fonts/montserrat-v15-latin-300.svg#Montserrat') format('svg');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('assets/fonts/montserrat-v15-latin-regular.eot');
    src: local(''), url('assets/fonts/montserrat-v15-latin-regular.eot?#iefix') format('embedded-opentype'), url('assets/fonts/montserrat-v15-latin-regular.woff2') format('woff2'), url('assets/fonts/montserrat-v15-latin-regular.woff') format('woff'), url('assets/fonts/montserrat-v15-latin-regular.ttf') format('truetype'), url('assets/fonts/montserrat-v15-latin-regular.svg#Montserrat') format('svg');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('assets/fonts/montserrat-v15-latin-500.eot');
    src: local(''), url('assets/fonts/montserrat-v15-latin-500.eot?#iefix') format('embedded-opentype'), url('assets/fonts/montserrat-v15-latin-500.woff2') format('woff2'), url('assets/fonts/montserrat-v15-latin-500.woff') format('woff'), url('assets/fonts/montserrat-v15-latin-500.ttf') format('truetype'), url('assets/fonts/montserrat-v15-latin-500.svg#Montserrat') format('svg');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('assets/fonts/montserrat-v15-latin-600.eot');
    src: local(''), url('assets/fonts/montserrat-v15-latin-600.eot?#iefix') format('embedded-opentype'), url('assets/fonts/montserrat-v15-latin-600.woff2') format('woff2'), url('assets/fonts/montserrat-v15-latin-600.woff') format('woff'), url('assets/fonts/montserrat-v15-latin-600.ttf') format('truetype'), url('assets/fonts/montserrat-v15-latin-600.svg#Montserrat') format('svg');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('assets/fonts/montserrat-v15-latin-800.eot');
    src: local(''), url('assets/fonts/montserrat-v15-latin-800.eot?#iefix') format('embedded-opentype'), url('assets/fonts/montserrat-v15-latin-800.woff2') format('woff2'), url('assets/fonts/montserrat-v15-latin-800.woff') format('woff'), url('assets/fonts/montserrat-v15-latin-800.ttf') format('truetype'), url('assets/fonts/montserrat-v15-latin-800.svg#Montserrat') format('svg');
}

@font-face {
    font-family: 'Maven Pro';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('assets/fonts/maven-pro-v21-latin-regular.eot');
    src: local(''), url('assets/fonts/maven-pro-v21-latin-regular.eot?#iefix') format('embedded-opentype'), url('assets/fonts/maven-pro-v21-latin-regular.woff2') format('woff2'), url('assets/fonts/maven-pro-v21-latin-regular.woff') format('woff'), url('assets/fonts/maven-pro-v21-latin-regular.ttf') format('truetype'), url('assets/fonts/maven-pro-v21-latin-regular.svg#MavenPro') format('svg');
}

@font-face {
    font-family: 'Maven Pro';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('assets/fonts/maven-pro-v21-latin-600.eot');
    src: local(''), url('assets/fonts/maven-pro-v21-latin-600.eot?#iefix') format('embedded-opentype'), url('assets/fonts/maven-pro-v21-latin-600.woff2') format('woff2'), url('assets/fonts/maven-pro-v21-latin-600.woff') format('woff'), url('assets/fonts/maven-pro-v21-latin-600.ttf') format('truetype'), url('assets/fonts/maven-pro-v21-latin-600.svg#MavenPro') format('svg');
}

html {
    min-height: 100%;
}

html,
body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-size: 62.5%;
    font-family: 'Montserrat', sans-serif;
    background-color: #ffffff;
}

select, option {
    font-family: 'Montserrat', sans-serif;
}
a {
    text-decoration: none;
    outline: 0;
    color: blue;
    font-weight: 500;
    &:hover {
        text-decoration: underline;
    }
    &:visited {
        color: blue;
    }
}

b {
    font-weight: 600!important;
}

mark {
    background-color: rgba(black, 1)!important;
    padding: 0 .25rem!important;
}

strong {
    font-size: 2.5rem;
    font-weight: 600;
    font-family: 'Maven Pro', sans-serif;
    margin: 3rem 0;
    line-height: 4rem;
}

h1 {
    font-size: 3rem;
    font-weight: 800;
}
h2 {
    font-size: 1.2rem;
    font-weight: 300;
}
h3 {
    font-size: 2rem;
}
h4 {
    font-size: 2rem;
}
h5 {
    font-size: 1.4rem;
}

@media screen and (min-width: 991px) {
    h1 {
        font-size: 5rem;
    }
    h2 {
        font-size: 1.8rem;
    }
}

p {
    font-size: 1.6rem;
    font-weight: 400;
    font-family: 'Maven Pro', sans-serif;
    margin: 0 0 2rem 0;
}

::-webkit-scrollbar {
    width: 4px;
}
  
::-webkit-scrollbar-track {
    background: transparent;
}
  
::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 4px;
    border: 0;
}

button {
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 4px;
    padding: 1.5rem 3rem;
    box-sizing: border-box;
    &.black {
        background-color: $black;
        border: 1px solid $black;
        color: $white;
    }
    &.white {
        background-color: $white;
        border: 1px solid $black;
        color: $black;
    }
    &.green {
        background-color: $primary;
        border: 1px solid $primary;
        color: $white;
    }
    &.red {
        background-color: $red;
        border: 1px solid $red;
        color: $white;
    }
    &:hover  {
        cursor: pointer;
        filter: contrast(.75);
    }
}